<template>
  <div class="model">
    <Form
      as="div"
      v-slot="{ meta }"
    >
      <input-with-label-validation
        rules="required"
        :name="$t('models.name')"
        @input="$emit('setModelValue', { handle: 'name', value: $event })"
        :value="model.name"
        :required="true"
        :tooltip="{ title: $t('models.modelNameTipTitle'), text: $t('models.modelNameTipText') }"
        :label="$t('models.name')"
        :placeholder="$t('models.name')"
      />

      <InputWithLabel
        @input="$emit('setModelValue', { handle: 'nameCn', value: $event })"
        :value="model.nameCn"
        :label="$t('models.cnName')"
        :placeholder="$t('models.cnName')"
        :tooltip="{title: $t('models.modelCnNameTipTitle'), text: $t('models.modelCnNameTipText')}"
      />

      <TheSelectBrandCategoryForm
        @onCreateNewBrand="showBrandPopup"
        @onCreateNewType="showTypePopup"
        @changeType="$emit('onTypeSelect', $event)"
        @changeBrand="$emit('onBrandSelect', $event)"
        @selectCategory="$emit('onSelectCategory', $event)"
        :selectedBrand="selectedBrand"
        :selectedType="selectedType"
        :categoryId="model.categoryId"
      />


      <div class="grid">
        <input-with-label-validation
          v-if="withHelpersData"
          rules="required"
          :name="$t('spu')"
          @input="$emit('setModelValue', { handle: 'spu', value: $event })"
          :value="model.spu"
          :required="true"
          :tooltip="{ title: $t('models.spuTipTitle'), text: $t('models.spuTipText') }"
          :label="$t('spu')"
          :placeholder="$t('spu')"
        />

        <input-with-label-validation
          v-if="withHelpersData"
          rules="required"
          :name="$t('hsCode')"
          @input="$emit('setHsCode', $event)"
          :value="hsCode"
          :required="true"
          :tooltip="{ title: $t('models.hsCodeTipTitle'), text: $t('models.hsCodeTipText') }"
          :label="$t('hsCode')"
          :placeholder="$t('hsCode')"
        />
      </div>

      <input-with-label-validation
        rules="required"
        :name="$t('models.description')"
        :value="model.description"
        :required="true"
        :tooltip="{ title: $t('models.descriptionTipTitle'), text: $t('models.descriptionTipText') }"
        :label="$t('models.description')"
      >
        <template #input>
            <textarea
              class="model-input base-textarea"
              name="description"
              :placeholder="$t('models.description')"
              :value="model.description"
              @input="$emit('setModelValue', { handle: 'description', value: $event.target.value })"
            ></textarea>
        </template>
      </input-with-label-validation>

      <template v-if="withHelpersData">
        <div class="title text-20-800">
          {{ $t('models.packageInfo') }}
        </div>

        <div class="dimensions">
          <input-with-label-validation
            :rules="{ required: true, moreThan: { min: 0 }}"
            :name="$t('models.weight')"
            @input="$emit('setDimension', { name: 'weight', value: $event})"
            :value="dimensions.weight"
            :required="true"
            :tooltip="{ title: $t('models.weightTipTitle'), text: $t('models.weightTipText') }"
            :label="$t('models.weight')"
            :placeholder="$t('models.weight')"
            type="number"
          />
          <input-with-label-validation
            :rules="{ required: true, moreThan: { min: 0 }}"
            :name="$t('models.length')"
            @input="$emit('setDimension', { name: 'length', value: $event})"
            :value="dimensions.length"
            :required="true"
            :tooltip="{ title: $t('models.lengthTipTitle'), text: $t('models.lengthTipText') }"
            :label="$t('models.length')"
            :placeholder="$t('models.length')"
            type="number"
          />
          <input-with-label-validation
            :rules="{ required: true, moreThan: { min: 0 }}"
            :name="$t('models.height')"
            @input="$emit('setDimension', { name: 'height', value: $event})"
            :value="dimensions.height"
            :required="true"
            :tooltip="{ title: $t('models.heightTipTitle'), text: $t('models.heightTipText') }"
            :label="$t('models.height')"
            :placeholder="$t('models.height')"
            type="number"
          />
          <input-with-label-validation
            :rules="{ required: true, moreThan: { min: 0 }}"
            :name="$t('models.width')"
            @input="$emit('setDimension', { name: 'width', value: $event})"
            :value="dimensions.width"
            :required="true"
            :tooltip="{ title: $t('models.widthTipTitle'), text: $t('models.widthTipText') }"
            :label="$t('models.width')"
            :placeholder="$t('models.width')"
            type="number"
          />
        </div>
      </template>

      <div class="button-wrap">
        <simple-button
          :value="$t('buttonNext')"
          :disabled="!meta.valid || isBrandCategorySelected === false"
          @click="$emit('onButtonClick')"
        />
      </div>

    </Form>

    <TheCreationPopup
      :type="type"
      @created="createPopup"
      @onClose="closePopup"
    />
  </div>
</template>

<script>
import TheSelectBrandCategoryForm from '@/components/ui/forms/TheSelectBrandCategoryForm.vue'
import { Form } from 'vee-validate'
import InputWithLabel from '@/components/ui/forms/InputWithLabel'
import TheCreationPopup from '@/components/ui/modals/TheCreationPopup'
import InputWithLabelValidation from '../../forms/InputWithLabelValidation'

export default {
  name: 'TheModel',
  components: {
    InputWithLabelValidation,
    TheCreationPopup,
    InputWithLabel,
    TheSelectBrandCategoryForm,
    Form,
  },
  props: {
    model: {
      type: Object,
    },
    dimensions: {
      type: Object,
    },
    hsCode: {
      type: String,
    },
    selectedBrand: {
      type: Object,
    },
    selectedType: {
      type: Object,
    },
    withHelpersData: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      type: '',
    }
  },
  methods: {
    showBrandPopup () {
      this.$store.commit('showOverlay')
      this.type = 'Brand'
    },
    showTypePopup () {
      this.$store.commit('showOverlay')
      this.type = 'Type'
    },
    async createPopup (value) {
      try {
        let type = this.type
        const addType = await this.$store.dispatch(`add${type}`, value)
        const typeId = addType?.data?.id || addType.id
        this.$store.commit('showHintNotification', {
          type: 'SUCCESS',
          title: `${this.$t('new')} ${type.toLowerCase()} ${this.$t('created')}`,
          description: `${this.$t('findItIn')} ${type.toLowerCase()} ${this.$t('search')}`,
        })

        const selectedType = {
          id: typeId,
          name: value,
          label: value,
        }

        await this.$store.dispatch(`push${type}`, selectedType)
        await this.$store.dispatch(`setModel${type}`, selectedType)
        this.$emit(`on${type}Select`, selectedType)

        this.closePopup()
      } catch (error) {
        let description = null
        if (error.response.data && error.response.data[0] && error.response.data[0].message) {
          description = error.response.data[0].message
        }
        this.$store.commit('showHintNotification', {
          type: 'ERROR',
          title: `${this.$t('createError')}`,
          description,
        })
      }
    },
    closePopup () {
      this.type = ''
      this.$store.commit('hideOverlay')
    },
  },
  computed: {
    isBrandCategorySelected () {
      return this.selectedBrand !== null && this.model.categoryId !== null
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-input {
  display: flex;
  width: 100%;
  justify-content: space-between;

  > span {
    flex: 1 1 auto;

    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  .base-input-container {
    width: 100%;
  }
}
.dimensions {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $color-grey;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
}

.base-textarea {
  border: 1px solid $color-grey;
  height: 96px;
  width: 100%;
  padding: 15px 20px;
  font-size: 14px;
  line-height: 16px;
  color: $text-gray;
  box-sizing: border-box;
  resize: none;

  &:focus,
  &:focus-visible{
    border: 1px solid $color-primary-focused;
    box-shadow: 0 1px 3px 0 rgba($color-primary, 0.3);
    outline: none;
  }
}
.title {
  margin-bottom: 20px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
}
</style>
