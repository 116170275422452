<template>
  <div class="category-brand">
    <InputWithLabel
      :label="$t('models.category')"
      :required="true"
      :tooltip="{title: $t('models.categoryTipTitle'), text: $t('models.categoryTipText')}"
    >
      <template #input>
        <div class="category-brand__field">
          <input
            class="input base-input categories"
            placeholder="Please select category"
            disabled
            :value="selectedCategoryObject.title"
          >
          <div class="category-brand__inner">
            <CloseButton
              class="reset"
              v-if="selectedCategoryObject.id"
              @onClick="resetCategory"
            />
            <button
              class="filter-field__btn filter-field__btn--menu"
              type="button"
              @click="toggleCategories"
            >
              <img src="@/assets/vertical-menu.svg" alt="">
            </button>
          </div>
        </div>
      </template>
    </InputWithLabel>
    <div class="grid">
      <InputWithLabel
        :label="$t('models.type')"
        :tooltip="{title: $t('models.typeTipTitle'), text: $t('models.typeTipText')}"
      >
        <template #input>
          <base-select
            ref="typeSelect"
            :placeholder="$t('models.selectType')"
            :options="typeList"
            :value="selectedType"
            :disabled="!newModel.model.categoryId"
            @input="changeType"
            @search="printSearchType"
          >
            <template #header>
              <div class="create-wrapper">
                <button class="create-btn" @click="onCreateNewType">{{ $t('models.newType') }}</button>
              </div>
            </template>
          </base-select>
        </template>
      </InputWithLabel>
      <InputWithLabel
        :label="$t('models.brand')"
        :required="true"
        :tooltip="{title: $t('models.brandTipTitle'), text: $t('models.brandTipText')}"
      >
        <template #input>
          <base-select
            ref="brandSelect"
            :placeholder="$t('models.selectType')"
            :options="brandList"
            :value="selectedBrand"
            @input="changeBrand"
            @search="printSearchBrand"
          >
            <template #header>
              <div class="create-wrapper">
                <button class="create-btn" @click="onCreateNewBrand">{{ $t('models.newBrand') }}</button>
              </div>
            </template>
          </base-select>
        </template>
      </InputWithLabel>
    </div>

    <SearchCategoryModal
      :isShow="categorySelectorVisible"
      :categoryId="newModel.model.categoryId"
      @onClose="categorySelectorVisible = false"
      @onSubmit="selectCategory"
      ref="categoryModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import debounce from 'lodash.debounce'
import helpers from '../../../helpers/helpers'
import SearchCategoryModal from '@/components/pages/search-product/SearchCategoryModal'
import CloseButton from '@/components/ui/forms/buttons/CloseButton'
import InputWithLabel from './InputWithLabel'

export default {
  name: 'TheSelectBrandCategoryForm',
  props: {
    selectedBrand: {
      type: Object,
      default: null,
    },
    selectedType: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      selectedCategory: {},
      categoryList: [],
      selectedCategoryObject: {
        label: null,
        id: null,
      },
      categorySelectorVisible: false,
      selectedSubCategory: null,
      selectedCategories: [],
    }
  },
  components: {
    InputWithLabel,
    CloseButton,
    SearchCategoryModal,
  },
  computed: {
    ...mapGetters(['isModelEdit', 'newModel', 'modelExists']),
    ...mapState({ brands: state => state.products.brand.items }),
    ...mapState({ categories: state => state.products.categories }),
    ...mapState({ types: state => state.products.types.items }),
    typeList () {
      return this.types.map(item => {
        return { id: item.id, label: item.name }
      })
    },
    brandList () {
      return this.brands.map(item => {
        return { id: item.id, label: item.name }
      })
    },
  },
  mounted () {
    if (this.modelExists) {
      this.$store.commit('emptyModelOffers')
    }

    this.$store.dispatch('getBrand', '')

    if (this.isModelEdit) {
      let { selectedBrand, selectedCategory, selectedType, newModel } = this
      this.$store.dispatch('getType', {
        categoryId: newModel.model.categoryId,
      })
      selectedBrand.label = newModel.brandName
      selectedCategory.label = newModel.categoryName
      selectedType.label = newModel.typeName
      selectedBrand.id = newModel.model.brandId
      selectedCategory.id = newModel.model.categoryId
      selectedType.id = newModel.model.typeId

      this.selectedCategoryObject = {
        title: newModel.categoryName,
        id: newModel.model.categoryId,
      }
    }
  },
  watch: {
    selectedBrand () {
      helpers.checkCreateStepSelect(this)
    },
    selectedCategoryObject () {
      helpers.checkCreateStepSelect(this)
    },
    selectedType () {
      helpers.checkCreateStepSelect(this)
    },
  },
  methods: {
    toggleCategories () {
      this.categorySelectorVisible = !this.categorySelectorVisible
    },
    selectCategory (categoryObject) {
      this.selectedCategory = categoryObject
      this.selectedCategoryObject = { ...categoryObject }
      this.categorySelectorVisible = false
      this.$store.dispatch('getType', {
        categoryId: categoryObject.id,
      })
      this.$emit('selectCategory', categoryObject)
      this.changeType(null)
    },
    resetCategory () {
      this.$emit('selectCategory', null)
      this.selectedCategoryObject = {}
      this.selectedCategory = null
      this.$refs.categoryModal.reset()
    },
    printSearchBrand: debounce(async function (searchText) {
      await this.$store.dispatch('getBrand', searchText)
    }, 1000),
    printSearchType: debounce(async function (searchText) {
      if (this.newModel && this.newModel.model.categoryId) {
        await this.$store.dispatch('getType', {
          categoryId: this.newModel ? this.newModel.model.categoryId : null,
          name: searchText,
        })
      }
    }, 1000),
    onCreateNewBrand () {
      this.$refs.brandSelect.$el.querySelector('input').blur()
      this.$emit('onCreateNewBrand')
    },
    onCreateNewType () {
      this.$refs.typeSelect.$el.querySelector('input').blur()
      this.$emit('onCreateNewType')
    },
    changeType (selectedType) {
      this.$emit('changeType', selectedType)
    },
    changeBrand (selectedBrand) {
      this.$emit('changeBrand', selectedBrand)
    },
  },
}
</script>

<style lang="scss" scoped>
.category-brand {
  &__field {
    width: 100%;
    box-sizing: border-box;
    position: relative;

    input.categories {
      border: 1px solid $color-grey;
      padding: 0 7px;
      box-sizing: border-box;
    }

    input.categories:disabled {
      background-color: #fff;
    }
  }

  &__inner {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 20px;
    @include flex(row, flex-end, center);
  }
}

.filter-field__btn--menu {
  border: none;
  background-color: transparent;
  outline: none;
  right: 10px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  img {
    display: block;
  }
}

.create {
  &-wrapper {
    @include flex(row, flex-end);
    padding: 10px;
  }

  &-btn {
    color: #34bebb;
    font-size: 14px;
    margin-left: auto;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
}
</style>
