<template>
  <div class="create" :class="overlayStatus && type ? 'active' : ''">
    <div class="create-content">
      <div class="create-header">
        <div class="title">{{ $t('createNew') }} {{ type.toLowerCase() }}</div>
        <CloseButton @onClick="$emit('onClose')" />
      </div>
      <base-input
        v-model="value"
        :fullWidth="true"
        :placeholder="type + ' name'"
        class="create__input"
      />
      <input
        type="button"
        class="base-button button"
        value="Create"
        @click="create">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CloseButton from '@/components/ui/forms/buttons/CloseButton'

export default {
  name: 'TheCreationPopup',
  components: {
    CloseButton,
  },
  props: {
    type: String,
  },
  data () {
    return {
      value: '',
    }
  },
  methods: {
    create () {
      this.$emit('created', this.value)
      this.value = ''
    },
  },
  computed: {
    ...mapState({ overlayStatus: state => state.ui.overlay }),
  },
}
</script>

<style scoped lang="scss">
.create {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  background: $color-white;
  width: 350px;
  position: fixed;
  text-align: center;
  z-index: 9;
  padding: 25px 20px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: none;

  &.active {
    @include flex(row, centere, center);
  }

  &-content {
    width: 100%;
  }

  &-header {
    @include flex(row, space-between);
    padding-bottom: 25px;
    border-bottom: 1px solid $color-line;
  }

  .title {
    font-size: 16px;
    color: #333333;
  }

  &__input {
    margin-top: 13px;
    margin-bottom: 20px;
  }
}
</style>
